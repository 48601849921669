import { colors } from './colors';

type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>;

export type Tuple<T, N extends number> = N extends N
  ? number extends N
    ? T[]
    : _TupleOf<T, N, []>
  : never;


type ColorKey = keyof typeof colors;

type MantineColors = {
  [key in ColorKey]: Tuple<string, 10>;
};

const mantineColors = Object.fromEntries(
  Object.entries(colors).map(([k, v]) => [k, Object.values(v)]),
) as MantineColors;

type CustomColors = keyof typeof mantineColors | (string & {}); // <<

declare module '@mantine/core' {
  // @ts-ignore 'theme type overrid'
  export type MantineThemeColorsOverride = {
    colors: Record<CustomColors, Tuple<string, 10>>;
  };
}

export type { MantineColors };
export { mantineColors };
