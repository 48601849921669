import React from "react";
import { useTelegram } from "../../hooks/useTelegram";
import "./Header.css";
import { Link } from "react-router-dom";
import { Button, Image } from "@mantine/core";

const Header = () => {
  const { user, onClose } = useTelegram();

  return (
    <div className={"header"}>
      {/* <Button onClick={onClose}>Закрыть</Button> */}
      <Link to={`/`} style={{ textDecoration: "none", color: "inherit" }}>
        <Button variant="filled" color="red" ml={20} radius={10}>
          <Image src="icons/arrow-left.svg" width="24" height="24" />
        </Button>
      </Link>
      <Link
        to={`/account`}
        className={"username"}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {user?.username}
      </Link>
    </div>
  );
};

export default Header;
