import {
    parseThemeColor,
    Tooltip,
    List,
    Menu,
    Alert,
    Notification,
    Accordion,
    Spoiler,
    Loader,
    Tabs,
    Modal,
    PasswordInput,
    NumberInput,
    Select,
    SegmentedControl,
    InputWrapper,
    Input,
    Drawer,
    createTheme,
    mergeMantineTheme,
    DEFAULT_THEME,
    rem,
} from '@mantine/core';
import { mantineColors } from './theme-colors-override';
  
  export const themeOverride = createTheme({
    scale: 1,
    colors: mantineColors,
    primaryColor: 'primary' as keyof typeof mantineColors,
    defaultRadius: 'sm',
    primaryShade: 5,
    components: {
      Drawer: Drawer.extend({
        styles: {
          title: {
            fontWeight: 700,
            textTransform: 'uppercase',
          },
        },
      }),
      Select: Select.extend({
        defaultProps: {},
      }),
      SegmentedControl: SegmentedControl.extend({
        classNames(theme, props) {
          const parsedColor = parseThemeColor({
            color: props.color || theme.primaryColor,
            theme,
          });
  
          return {
            label: parsedColor.color.includes('primary')
              ? '[[data-active]&]:!text-black'
              : undefined,
          };
        },
        defaultProps: {},
      }),
      InputWrapper: InputWrapper.extend({
        defaultProps: {
          inputWrapperOrder: ['label', 'input', 'description', 'error'],
        },
      }),
      Input: Input.extend({
        vars: (_, props) => {
          return {
            wrapper: {
              // force to use specific font-size mapped to input size
              '--input-fz': `var(--input-font-size-${props.size})`,
              // NOTE: we defined by hand this variable on the root element of input, and skip local definitions
              '--input-height': 'inherit',
            },
          };
        },
        styles: {
          input: {
            fontWeight: 700,
          },
        },
        defaultProps: {
          size: 'md',
        },
      }),
      PasswordInput: PasswordInput.extend({
        styles: {
          innerInput: {
            paddingLeft: 'inherit',
            height: 'inherit',
            paddingTop: 'inherit',
          },
          // visibilityToggle: {
          //   color: theme.colors.gray[6],
          // },
          section: {
            width: rem(36),
          },
        },
        defaultProps: {},
      }),
      NumberInput: NumberInput.extend({
        defaultProps: {
          hideControls: true,
        },
      }),  
      Loader: Loader.extend({
        defaultProps: {
          color: 'currentColor',
          type: 'dots',
          size: 'md',
        },
      }),
      Tabs: Tabs.extend({}),
      Tooltip: Tooltip.extend({
        defaultProps: {
          transitionProps: {
            transition: 'fade',
          },
          events: {
            hover: true,
            focus: true,
            touch: true,
          },
        },
      }),
      Modal: Modal.extend({
        styles: {
          inner: {
            overflowX: 'hidden',
          },
          content: {
            maxWidth: '100%', // fix overflow
          },
        },
      }),
      List: List.extend({
        styles: {
          root: {
            listStylePosition: 'inside',
          },
          item: {
            whiteSpace: 'nowrap',
          },
          itemWrapper: {
            display: 'inline-flex',
            whiteSpace: 'normal',
            flexDirection: 'column',
          },
        },
      }),
      Menu: Menu.extend({
        styles: {
          item: {
            fontSize: 'inherit',
          },
        },
      }),
      Alert: Alert.extend({}),
      Notification: Notification.extend({}),
      Accordion: Accordion.extend({}),
      Spoiler: Spoiler.extend({}),
    },
  });
  
  export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);
  