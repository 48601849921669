import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { api } from "../../models/api";
import { useTelegram } from "../../hooks/useTelegram";

const { user: tgUser } = useTelegram();
const tgUserStore = createStore(tgUser);

const calendarUpdate = createEvent<{
  calendarLogin: string;
  calendarPassword: string;
  calendarCaldav: string;
}>();

const putCalendarUpdate = attach({
  effect: createEffect(api.user.calendarUpdate),
});

const loading = putCalendarUpdate.pending;

sample({
  clock: calendarUpdate,
  source: tgUserStore,
  fn: (store, update) => ({ tgId: store.id, ...update }),
  target: putCalendarUpdate,
});

export const accountPageModel = {
  calendarUpdate,
  loading,
};
