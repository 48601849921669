import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CommentLine from "./Comment";
import { CommentSectionType } from "./types";
import { createView } from "../../models/view";
import { postPageModel } from "../../pages/post/post.model";
import { Comment } from "../../models/api";
import { Button, Group, Image } from "@mantine/core";

const CommentsSection = createView<CommentSectionType>()
  .units({
    addComment: postPageModel.addComment,
    editComment: postPageModel.editComment,
    getPostPending: postPageModel.getPostPending,
  })
  .map(({ commentsData, addComment, editComment }) => {
    const [newComment, setNewComment] = useState("");
    const [replyId, setReplyId] = useState<string | null>(null);
    const [editId, setEditId] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const [inputFocused, setInputFocused] = useState(false);
    const commentWrapper = inputWrapperRef.current;

    const inputBlur = () => {
      // console.log(inputFocused);
      if (inputFocused) {
        inputRef.current?.blur();
        // setTimeout(() => setInputFocused(false), 300)
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", inputBlur);
      return () => {
        window.removeEventListener("scroll", inputBlur);
      };
    }, [inputFocused, inputRef]);

    useEffect(() => {
      bottomRef.current?.scrollIntoView();
    }, []);

    const replyCommentItem: Comment | null = useMemo(
      () =>
        replyId
          ? commentsData.find((comment) => comment.id === replyId)!
          : null,
      [replyId]
    );

    const editCommentItem: Comment | null = useMemo(
      () =>
        editId ? commentsData.find((comment) => comment.id === editId)! : null,
      [editId]
    );

    const onAdd = () => {
      if (editId) editComment({ commId: editId, text: newComment });
      else if (newComment)
        addComment({
          text: newComment,
          parentCommentId: replyId,
        });
      setNewComment("");
      setReplyId("");
      setEditId("");
      setTimeout(() => bottomRef.current?.scrollIntoView(), 100);
    };

    return {
      newComment,
      setNewComment,
      setReplyId,
      replyId,
      replyCommentItem,
      editCommentItem,
      editId,
      setEditId,
      inputRef,
      onAdd,
      bottomRef,
      inputFocused,
      setInputFocused,
      inputWrapperRef,
    };
  })
  .view(
    ({
      commentsData,
      newComment,
      setNewComment,
      setReplyId,
      replyId,
      getPostPending,
      replyCommentItem,
      editCommentItem,
      editId,
      setEditId,
      inputRef,
      onAdd,
      bottomRef,
      setInputFocused,
      inputWrapperRef,
    }) => (
      <div className="commentSection">
        <div className="commentsData">
          {commentsData.map((comment) => (
            <CommentLine
              key={`comment-${comment.id}`}
              fullName={comment.fullName}
              text={comment.text}
              imgUrl={
                comment.picturesUrl[0] ??
                `https://ui-avatars.com/api/name=${comment.fullName}&background=random`
              }
              id={comment.id}
              parentCommentId={comment.parentCommentId}
              repliedTo={commentsData.find(
                (replyComment) => replyComment.id === comment.parentCommentId
              )}
              setReplyId={setReplyId}
              replyId={replyId}
              tgId={comment.tgId}
              editId={editId}
              setEditId={setEditId}
              inputRef={inputRef}
              date={comment.dateCreated}
            />
          ))}
        </div>
        <span ref={bottomRef} />
        <div ref={inputWrapperRef} className="commentWrapper">
          <div className="leaveComment">
            {replyCommentItem && (
              <Group className="additionalBlockInput" justify="space-between">
                <div>
                  <p>Ответить на:</p>
                  <Group>
                    <div>{replyCommentItem.fullName}:</div>
                    <div>{replyCommentItem.text}</div>
                  </Group>
                </div>
                <Button
                  style={{ backgroundColor: "var(--third-color)" }}
                  onClick={() => setReplyId(null)}
                >
                  X
                </Button>
              </Group>
            )}
            {editCommentItem && (
              <Group className="additionalBlockInput" justify="space-between">
                <div>
                  <p>Редактировать:</p>
                  <Group>
                    <div>{editCommentItem.fullName}:</div>
                    <div>{editCommentItem.text}</div>
                  </Group>
                </div>
                <Button
                  style={{ backgroundColor: "var(--third-color)" }}
                  onClick={() => setEditId(null)}
                >
                  X
                </Button>
              </Group>
            )}
            <div className="inputBlock">
              <input
                ref={inputRef}
                value={newComment}
                className="mainInput"
                onChange={(e) => setNewComment(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onAdd()}
                placeholder={editId ? "Изменить комментарий" : "Написать комментарий!"}
                onFocus={() => setTimeout(() => setInputFocused(true), 300)}
                onBlur={() => setInputFocused(false)}
              />
              <button
                className="mainButton"
                onClick={onAdd}
                disabled={getPostPending}
              >
                <Image src="icons/send.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );

export default CommentsSection;
