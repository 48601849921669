import { is, combine } from 'effector';
// See: https://github.com/effector/effector/issues/817
import { useUnit, useStoreMap } from 'effector-react';
import { createLib } from 'effector-view';

const { createView, connect } = createLib({
  combine,
  useUnit,
  useStoreMap,
  is,
});

export { createView, connect };