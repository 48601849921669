import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Leader } from "../../models/api";
import "moment/locale/ru";
import {
  getDateWithPadding,
  getDiffMinutes,
  localizer,
  russian,
} from "./calendar.utils";
import CustomResourceHeader from "./Header";
import EventModal from "./EventModal/event-modal.component";
import "./calendar.css";
import type {
  CalendarComponent as CalendarEvent,
  MyEvent,
  MyResource,
} from "./calendar.types";
import { Card, Flex, SimpleGrid, Loader } from "@mantine/core";
import {
  Datepicker,
  DatepickerEvent,
} from "@meinefinsternis/react-horizontal-date-picker";
import { enUS } from "date-fns/locale";
import moment from "moment";

const CalendarComponent = ({
  leader,
  loadingEvents,
  reload,
}: {
  leader: Leader;
  subscriber: {
    id: number;
    username: string;
  };
  loadingEvents: boolean;
  reload: ({
    timeEnd,
    timeStart,
  }: {
    timeEnd: string;
    timeStart: string;
  }) => void;
}) => {
  const [events, setEvents] = useState<MyEvent[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<MyEvent | null>(null);
  const [view, setView] = useState<"calendar" | "list">("list");

  // const toggleView = () => {
  //   setView(view === "calendar" ? "list" : "calendar");
  // };

  const openModal = (event: MyEvent) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };
  const [date, setDate] = React.useState<{
    startValue: Date | null;
  }>({
    startValue: new Date(),
  });

  const handleChange = (d: DatepickerEvent) => {
    const [startValue, endValue, rangeDates] = d;
    if (startValue) {
      reload({
        timeEnd: getDateWithPadding(startValue, 1).toISOString() ?? "",
        timeStart: startValue.toISOString() ?? "",
      });
    }
    setDate(() => ({ startValue }));
  };

  const closeModal = (rel = false) => {
    if (rel && date.startValue) {
      reload({
        timeEnd: getDateWithPadding(date.startValue, 1).toISOString() ?? "",
        timeStart: date.startValue.toISOString() ?? "",
      });
    }
    setModalIsOpen(false);
  };

  const eventPropGetter = useCallback((event: { title?: string }) => {
    if (event.title?.includes("#cost")) {
      return {
        style: { backgroundColor: "green" },
      };
    }

    if (event.title?.includes("#booked")) {
      return {
        style: { backgroundColor: "#cd5700" },
      };
    }

    return {};
  }, []);

  useEffect(() => {
    try {
      const fetchICalData = async () => {
        try {
          // leader.calendar = (
          //   await axios.get(process.env.REACT_APP_BASE_URL + "/calendar")
          // ).data;
          const parsedEvents = leader.calendar.map((event: CalendarEvent) => {
            return {
              uid: event.uid,
              resourceId: "resourceId",
              title: event.summary,
              description: event.description,
              start: new Date(event.start?.toString() ?? ""),
              end: new Date(event.end?.toString() ?? ""),
              cost: event.cost,
            } as MyEvent;
          });
          setEvents(parsedEvents);
        } catch (error) {
          console.error("Error fetching iCal data", error);
        }
      };
      fetchICalData();
    } catch (error) {
      console.error("Error fetching iCal data", error);
    }
  }, [leader, leader.calendar]);

  useEffect(() => {
    if (date.startValue) {
      reload({
        timeEnd: getDateWithPadding(date.startValue, 1).toISOString() ?? "",
        timeStart: date.startValue.toISOString() ?? "",
      });
    }
  }, []);

  const { components } = useMemo(
    () => ({
      components: {
        resourceHeader: CustomResourceHeader,
      },
    }),
    []
  );

  return (
    <div style={{ textAlign: "center", color: "white" }}>
      <Datepicker
        startDate={getDateWithPadding(new Date(), -3)}
        onChange={handleChange}
        locale={enUS}
        startValue={date.startValue}
        endValue={date.startValue}
      />
      <h3 style={{ color: "white" }}>Ивенты</h3>
      {/* <Button onClick={toggleView}>
        {view === "calendar" ? "Показать список" : "Показать календарь"}
      </Button> */}
      <div>
        {view === "calendar" ? (
          <Calendar<MyEvent, MyResource>
            messages={russian}
            localizer={localizer}
            components={components}
            events={events as MyEvent[]}
            eventPropGetter={eventPropGetter}
            onSelectEvent={(event: MyEvent) => openModal(event)}
            style={{ height: 500, margin: "10px" }}
            startAccessor="start"
            endAccessor="end"
            defaultView={"day"}
            views={["day", "week"]}
            resourceIdAccessor={"resourceId"}
            resources={[{ resourceId: "resourceId", resourceIdTitle: "" }]}
          />
        ) : (
          <>
            {loadingEvents ? (
              <Flex align={'center'}  direction={"column"}><Loader /></Flex>
            ) : (
              <div style={{ margin: "5px", color: "white" }}>
                {/* <h4>Список всех ивентов</h4> */}
                <ul>
                  {events.map((event, index) => (
                    <Card
                      key={index}
                      onClick={() => openModal(event)}
                      m={5}
                      style={{ borderRadius: 15 }}
                      h={60}
                      p={5}
                    >
                      <SimpleGrid cols={3} spacing={0}>
                        <div
                          style={{ fontWeight: "bold", alignContent: "center" }}
                        >
                          {moment(event.start).format("HH:mm")}
                        </div>
                        <Flex direction={"column"}>
                          <div style={{ fontWeight: "bold" }}>Событие</div>
                          <div style={{ fontWeight: "bold" }}>
                            ({getDiffMinutes(event.start, event.end)} мин)
                          </div>
                        </Flex>
                        <div
                          style={{ fontWeight: "bold", alignContent: "center" }}
                        >
                          {event.cost ? `${event.cost}р.` : "Без цены"}
                        </div>
                      </SimpleGrid>
                    </Card>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
      {selectedEvent && (
        <EventModal
          opened={modalIsOpen}
          event={selectedEvent}
          close={closeModal}
          leader={leader}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
