import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from "effector";
import { Leader, Post, Comment, api } from "../../models/api";
import { useTelegram } from "../../hooks/useTelegram";
import { interval } from "patronum/interval";

const { user: tgUser } = useTelegram();
const setPostId = createEvent<string>("");
const setPost = createEvent<Post | null>();
const setComments = createEvent<Comment[]>();
const startIntervalPost = createEvent();
const addComment = createEvent<{
  text: string;
  parentCommentId: string | null;
}>();
const editComment = createEvent<{ commId: string; text: string }>();
const deleteComment = createEvent<{ commId: string }>();

const postId = createStore<string>("").on(setPostId, (_, id) => id);

const tgUserStore = createStore(tgUser);
const post = createStore<Post | null>(null);
const comments = createStore<Comment[]>([]);

const getPost = attach({
  effect: createEffect(api.post.getPost),
});

const addCommentFx = attach({
  effect: createEffect(api.post.comment),
});

const deleteCommentFx = attach({
  effect: createEffect(api.post.deleteComment),
});

const editCommentFx = attach({
  effect: createEffect(api.post.editComment),
});

const getPostPending = getPost.pending;

sample({
  clock: postId,
  fn: (id) => ({ id }),
  target: getPost,
});

sample({
  clock: addComment,
  source: { tgUserStore: tgUserStore, post: post },
  fn: ({ tgUserStore, post }, newComment) => ({
    ...newComment,
    tgId: tgUserStore.id,
    id: post?.id ?? "",
    fullName: tgUserStore.username ?? "",
  }),
  target: addCommentFx,
});

sample({
  clock: editComment,
  fn: (edit) => edit,
  target: editCommentFx,
});

sample({
  clock: deleteComment,
  fn: (edit) => edit,
  target: deleteCommentFx,
});

const { tick, isRunning } = interval({
  timeout: 30000,
  start: startIntervalPost,
});

sample({
  clock: tick,
  source: postId,
  fn: (id) => ({ id }),
  target: getPost,
});

sample({
  clock: [addCommentFx.done, editCommentFx.done, deleteCommentFx.done],
  source: postId,
  fn: (data) => ({ id: data }),
  target: getPost,
});

getPost.done.watch(({ result }) => {
  setPost(result.post ?? null);
  setComments(result.comments ?? []);
  startIntervalPost();
});

sample({
  clock: setPost,
  target: post,
});

sample({
  clock: setComments,
  target: comments,
});

export const postPageModel = {
  setPostId,
  postId,
  getPost,
  post,
  comments,
  getPostPending,
  tgUserStore,
  addComment,
  editComment,
  deleteComment,
};
