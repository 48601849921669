import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { Booking, Leader, api } from "../../../models/api";
import axios from "axios";
import { useTelegram } from "../../../hooks/useTelegram";
import { showNotificationFx } from "../../../models/notification";

const bookingEvent = attach({
  effect: createEffect(api.user.booking),
});

const unbookingEvent = attach({
  effect: createEffect(api.user.unbooking),
});

const { user: tgUser, onClose } = useTelegram();
const tgUserStore = createStore(tgUser);

const eventSelected = createEvent<{
  eventId: string;
  leadTgId: number;
  start: string;
}>();

const eventUnSelected = createEvent<{
  eventId: string;
  leadTgId: number;
  start: string;
}>();

const result = createStore<Booking>({
  success: false,
  message: "Не удалось забронировать слот",
});

const resultCansel = createStore<Booking>({
  success: false,
  message: "Не удалось отменить бронь слота",
});

const loading = bookingEvent.pending;

const loadingUnbooking = unbookingEvent.pending;

sample({
  clock: eventSelected,
  source: tgUserStore,
  fn: (tgUser, event) => ({ tgId: tgUser.id, ...event }),
  target: bookingEvent,
});

sample({
  clock: eventUnSelected,
  source: tgUserStore,
  fn: (tgUser, event) => ({ tgId: tgUser.id, ...event }),
  target: unbookingEvent,
});

sample({
  clock: bookingEvent.done,
  fn: ({ result }) => result,
  target: result,
});

sample({
  clock: unbookingEvent.done,
  fn: ({ result }) => result,
  target: resultCansel,
});

sample({
  source: result,
  fn: ({ success, message }) => {
    if (success) {
      onClose();
    }
    return {
      message: message ? message : "Не удалось забронировать слот",
      color: success ? "success" : "danger",
    };
  },
  target: showNotificationFx,
});

sample({
  source: result,
  fn: ({ success, message }) => {
    return {
      message: message ? message : "Отмена",
      color: success ? "success" : "danger",
    };
  },
  target: showNotificationFx,
});

export const eventModalModel = {
  eventSelected,
  loading,
  result,
  eventUnSelected,
  loadingUnbooking,
  resultCansel,
};
