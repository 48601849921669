import { useState } from "react";
import { Center, Stack, TextInput, Button, Loader } from "@mantine/core";
import { createView } from "../../models/view";
import { accountPageModel } from "./account.model";

const LoginPage = createView<object>()
  .displayName("LoginPage")
  .units({
    calendarUpdate: accountPageModel.calendarUpdate,
    loading: accountPageModel.loading,
  })
  .map(({ calendarUpdate }) => {
    const [calendarCaldav, setCalendarCaldav] = useState("");
    const [calendarLogin, setCalendarLogin] = useState("");
    const [calendarPassword, setCalendarPassword] = useState("");

    const handleLogin = async () => {
      calendarUpdate({ calendarLogin, calendarPassword, calendarCaldav });
    };

    return {
      calendarLogin,
      setCalendarLogin,
      calendarPassword,
      setCalendarPassword,
      handleLogin,
      calendarCaldav,
      setCalendarCaldav,
    };
  })
  .memo()
  .view(
    ({
      calendarLogin,
      setCalendarLogin,
      calendarPassword,
      setCalendarPassword,
      handleLogin,
      loading,
      calendarCaldav,
      setCalendarCaldav,
    }) => {
      return (
        <Center style={{ height: "100vh" }}>
          <Stack style={{ width: "300px" }}>
            <TextInput
              label="CalDav url"
              value={calendarCaldav}
              onChange={(e) => setCalendarCaldav(e.currentTarget.value)}
              placeholder="Enter caldav url"
            />
            <TextInput
              label="Login"
              value={calendarLogin}
              onChange={(e) => setCalendarLogin(e.currentTarget.value)}
              placeholder="Enter your login"
            />
            <TextInput
              label="Password"
              value={calendarPassword}
              onChange={(e) => setCalendarPassword(e.currentTarget.value)}
              placeholder="Enter your calendarPassword"
            />
            <Button onClick={handleLogin} disabled={loading} fullWidth>
              {loading ? <Loader size="xs" /> : "Save"}
            </Button>
          </Stack>
        </Center>
      );
    }
  ).Memo;

export default LoginPage;
