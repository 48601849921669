import React, { useState } from "react";
import { CommentLineType } from "./types";
import "./comment-styles.css";
import clsx from "clsx";
import CommentActions from "./comment-actions";
import { createView } from "../../models/view";
import { postPageModel } from "../../pages/post/post.model";
import { Image } from "@mantine/core";

const getEnumEnding = (num: number, textAr: [string, string, string]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  
  const mod100 = num % 100;
  const mod10 = num % 10;

  let form;
  if (mod100 > 4 && mod100 < 21) {
      form = 2;
  } else {
      form = cases[(mod10 < 5) ? mod10 : 5];
  }

  return textAr[form];
} 

function formatTimeDifference(dateString: string): string {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  const diffInMilliseconds = currentDate.getTime() - inputDate.getTime();
  const diffInMinutes = Math.floor(Math.abs(diffInMilliseconds / (1000 * 60)));
  const diffInHours = Math.floor(Math.abs(diffInMilliseconds / (1000 * 60 * 60)));
  const diffInDays = Math.floor(Math.abs(diffInMilliseconds / (1000 * 60 * 60 * 24)));

  const minutes: [string, string, string] = ["минуту", "минуты", "минут"]
  const hours: [string, string, string] = ["час", "часа", "часов"]
  const days: [string, string, string] = ["день", "дня", "дней"]

  if (diffInDays >= 1) {
    return `${diffInDays} ${getEnumEnding(diffInDays, days)} назад`;
  } else if (diffInHours >= 1) {
    return `${diffInHours} ${getEnumEnding(diffInHours, hours)} назад`;
  } else if (diffInMinutes === 0) {
    return "Только что";
  } else {
    return `${diffInMinutes} ${getEnumEnding(diffInMinutes, minutes)} назад`;
  }
}

const CommentLine = createView<CommentLineType>()
  .units({
    addComment: postPageModel.addComment,
    getPostPending: postPageModel.getPostPending,
    tg: postPageModel.tgUserStore,
  })
  .map(({ text }) => {
    const [replyText, setReplyText] = useState("");
    const [newText, setNewText] = useState(text);

    return {
      replyText,
      setReplyText,
      newText,
      setNewText,
    };
  })
  .view(
    ({
      text,
      fullName,
      imgUrl,
      date,
      id,
      repliedTo,
      replyId,
      setReplyId,
      newText,
      tgId,
      tg,
      editId,
      setEditId,
      inputRef,
    }) => (
      <div className={clsx("commentLine", "parent")}>
        <div className="user">
          <div className="imgName">
            <Image className="pfp" src={imgUrl} />
            <div>
              <h3>{fullName}</h3>
              {date ? <p>{formatTimeDifference(date.toString())}</p> : ""}
            </div>
          </div>
          <CommentActions
            editId={editId}
            setEditId={setEditId}
            replyId={replyId}
            setReplyId={setReplyId}
            text={text}
            id={id}
            newText={newText}
            tgId={tgId}
            tg={tg}
            inputRef={inputRef}
          />
        </div>
        {repliedTo && (
          <div className="replyComment">
            {repliedTo.fullName}: {repliedTo.text}
          </div>
        )}
        <p className="commentText">{text}</p>
      </div>
    )
  );

export default CommentLine;
