import { Button, Group, Modal } from "@mantine/core";
import "./comment-styles.css";

interface ConfirmDeleteModalType {
  onClose: () => void;
  opened: boolean;
  deleteComment: () => void;
}

export const ConfirmDeleteModal = ({
  onClose,
  opened,
  deleteComment,
}: ConfirmDeleteModalType) => {
  return (
    <Modal
      title="Удалить комментарий?"
      size="lg"
      onClose={onClose}
      opened={opened}
      centered
      styles={{
        header: {
          backgroundColor: "#212121",
          color: "white",
        },
        content: {
          backgroundColor: "#212121",
          color: "white",
        },
      }}
    >
      <Group gap="6px">
        <Button style={{ backgroundColor: "red" }} onClick={deleteComment}>
          Да
        </Button>
        <Button
          style={{ backgroundColor: "white", color: "black" }}
          onClick={onClose}
        >
          Нет
        </Button>
      </Group>
    </Modal>
  );
};
