import { Accordion, Button } from "@mantine/core";
import { createView } from "../../models/view";
import { tagModel } from "./tags.model";
import PersonList from "../PersonList/PersonList";
import { useEffect } from "react";
import { useMantineTheme } from "@mantine/core";
import "./tag.css";

const TagsComponent = createView()
  .displayName("TagsComponent")
  .units({
    activeTag: tagModel.activeTag,
    tagSelected: tagModel.tagSelected,
    activeUsers: tagModel.activeUsers,
    opened: tagModel.opened,
  })
  .map(({ opened, tagSelected, activeTag }) => {
    useEffect(() => {
      opened();
    }, []);

    const mock = [
      {
        label: "Бег",
        value: "jogging",
        data: [
          { label: "Спринт", value: "sprint" },
          { label: "Марафон", value: "maraphone" },
        ],
      },
      {
        label: "Бокс",
        value: "boxing",
        data: [
          { label: "Классический", value: "classic" },
          { label: "Тайский", value: "thai" },
          { label: "Кикбоксинг", value: "kickboxing" },
        ],
      },
      {
        label: "Йога",
        value: "yoga",
        data: [
          { label: "Хатха", value: "hatha" },
          { label: "Бхакти", value: "bhakti" },
          { label: "Джавамукти", value: "jivamukti" },
        ],
      },
      {
        label: "Плавание",
        value: "swimming",
        data: [
          { label: "Брас", value: "breaststroke" },
          { label: "Кроль", value: "freestyle" },
        ],
      },
    ];

    const accordionItems = mock.map((item) => (
      <Accordion.Item key={item?.value} value={item?.label}>
        <Accordion.Control
          styles={{
            control: {
              backgroundColor: "#181818",
            },
          }}
          style={{ color: "white" }}
          className="accordionControl"
        >
          {item?.label}
        </Accordion.Control>
        <Accordion.Panel>
          {item.data.map((tag) => (
            <div>
              <button
                onClick={() => onChange(tag?.value)}
                className={`tagBtn ${
                  activeTag === tag?.value ? "selected" : ""
                }`}
              >
                {tag?.label}
              </button>
            </div>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    ));

    const onChange = (value: string | null) => {
      if (value) {
        tagSelected(value);
      }
    };

    return { mock, onChange, accordionItems };
  })
  .memo()
  .view(
    ({ className, activeTag, onChange, activeUsers, mock, accordionItems }) => {
      const theme = useMantineTheme();

      return (
        <div className={className}>
          {/* <Button
            variant="filled"
            color="red"
            ml={10}
            radius={10}
            h={35}
            onClick={() => onChange("init")}
          >
            Сброс фильтра
          </Button>
          <Accordion>{accordionItems}</Accordion> */}
          <PersonList users={activeUsers} />
        </div>
      );
    }
  ).Memo;

export { TagsComponent };
