import { Link, useLocation } from "react-router-dom";
import {
  Card,
  Group,
  Image,
  Text,
  Badge,
  Button,
  Stack,
  Center,
  Loader,
  ActionIcon,
} from "@mantine/core";
import { createView } from "../../models/view";
import { userPageModel } from "./user.model";
import { useEffect } from "react";
import CalendarComponent from "../../components/Calendar/calendar.component";
import { Flex } from "@mantine/core";
import "./user.css";
import { Carousel } from "@mantine/carousel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserPage = createView()
  .displayName("UserPage")
  .units({
    setUserId: userPageModel.setUserId,
    user: userPageModel.user,
    isSubscriber: userPageModel.isSubscriber,
    toggleSubc: userPageModel.toggleSubc,
    toggleUnSubc: userPageModel.toggleUnSubc,
    toggleReload: userPageModel.toggleReload,
    getUserPending: userPageModel.getUserPending,
    getLeadEventsPending: userPageModel.getLeadEventsPending,
    tgUser: userPageModel.tgUserStore,
  })
  .map(({ setUserId }) => {
    const query = useQuery();
    const id = query.get("id");

    useEffect(() => {
      if (id) {
        setUserId(id);
      }
    }, [id, setUserId]);

    return {};
  })
  .memo()
  .view(
    ({
      user,
      isSubscriber,
      toggleSubc,
      toggleUnSubc,
      getUserPending,
      tgUser,
      toggleReload,
      getLeadEventsPending,
    }) => {
      if (!user || getUserPending) {
        return (
          <Center style={{ height: "100vh" }}>
            <Loader size="xl" />
          </Center>
        );
      }
      return (
        <div>
          <Card
            key={user.id}
            shadow="xl"
            padding="xl"
            radius="xl"
            maw={600}
            m={10}
            className="user-card"
          >
            <Group align="flex-start">
              <Carousel
                slideSize="auto"
                slideGap="sm"
                align="start"
                slidesToScroll={1}
                m={10}
                withControls={false}
                classNames={{
                  viewport: "flex overflow-x-auto",
                }}
              >
                {user?.picturesUrl?.map((el: string, index: number) => (
                  <Image
                    key={`image-${
                      user.id
                    }-${index}-${new Date().getMilliseconds()}`}
                    className="person-img"
                    src={el}
                    height={"100%"}
                    width={"100%"}
                    alt={user.name}
                  />
                ))}
              </Carousel>
              <Group justify="space-between" style={{ width: "100%" }}>
                <Stack style={{ flex: 1 }}>
                  <Stack gap={0}>
                    <Stack gap={0}>
                      <span>Hi, I'm</span>
                      <span className="name-big">{user?.name}</span>
                    </Stack>
                    <Flex gap={"1vw"}>
                      <Text className="subs-text">@{user?.nick}</Text>
                    </Flex>
                    <Flex gap={"1vw"}>
                      <span>Followers:</span>
                      <Text className="subs-text badge">
                        {user?.subscribers?.length}
                      </Text>
                    </Flex>
                  </Stack>
                </Stack>
                <Group style={{ flexWrap: "wrap" }}>
                  <ActionIcon
                    component="a"
                    href={user.telegramUrl}
                    data-disabled
                    size="xl"
                    aria-label="Open in a new tab"
                  >
                    <Image src={"icons/brand-telegram.svg"}></Image>
                  </ActionIcon>
                  <ActionIcon
                    component="a"
                    href={user.instagramUrl}
                    data-disabled
                    size="xl"
                    aria-label="Open in a new tab"
                  >
                    <Image src={"icons/brand-instagram.svg"}></Image>
                  </ActionIcon>
                  <ActionIcon
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={user.materialsUrl}
                    data-disabled
                    size="xl"
                    aria-label="Open in a new tab"
                  >
                    <Image src={"icons/materials.svg"}></Image>
                  </ActionIcon>
                </Group>
              </Group>
            </Group>
            <Text size="sm" mb={10} style={{ marginTop: 10 }}>
              {user?.description}
            </Text>
            <Group>
              {user?.tags?.map((tag: string) => (
                <Badge color="white" variant="light">
                  {tag}
                </Badge>
              ))}
            </Group>
            <Button
              variant="light"
              color="blue"
              fullWidth
              style={{ marginTop: 14 }}
              onClick={() => (isSubscriber ? toggleUnSubc() : toggleSubc())}
              className="subscribe-btn"
            >
              {isSubscriber ? "Отписаться" : "Подписаться"}
            </Button>
          </Card>
          <CalendarComponent
            subscriber={tgUser}
            leader={user}
            loadingEvents={getLeadEventsPending}
            reload={toggleReload}
          />
        </div>
      );
    }
  ).Memo;

export default UserPage;
