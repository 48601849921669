import "./PersonList.css";
import PersonItem from "../PersonItem/PersonItem";
import { User } from "../../models/api";
import { Stack } from "@mantine/core";

const PersonList = ({ users }: { users: User[] }) => {
  return (
    <Stack gap={"1.2vw"}>
      {users.map((user) => (
        <PersonItem
          key={user.id}
          user={user}
          onAdd={() => {}}
          className={"item"}
        />
      ))}
    </Stack>
  );
};

export default PersonList;
