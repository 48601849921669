/**
 * Extend default Tailwind color palette with missing Mantine colors
 * NOTE: Mantine supports only 10 colors in each color-shade
 * Because of typescript issues we copypasted tw colors & replaced needed values
 * We omitted string values like black and white, so we added it only inside tailwind config
 */
const colorPalette = {
    // Mantine colors, that's missing in tw palette
    dark: {
      50: '#c1c2c5',
      100: '#a6a7ab',
      200: '#909296',
      300: '#5c5f66',
      400: '#373a40',
      500: '#2c2e33',
      600: '#25262b',
      700: '#1a1b1e',
      800: '#141517',
      900: '#101113',
    },
    grape: {
      50: '#f8f0fc',
      100: '#f3d9fa',
      200: '#eebefa',
      300: '#e599f7',
      400: '#da77f2',
      500: '#cc5de8',
      600: '#be4bdb',
      700: '#ae3ec9',
      800: '#9c36b5',
      900: '#862e9c',
    },
    // Override some Tailwind colors
    // Tailwind
    slate: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
  
    zinc: {
      50: '#fafafa',
      100: '#f4f4f4',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
    },
    neutral: {
      50: '#fafafa',
      // for panels (top menu), inactive tabs/blocks fill
      100: '#f8f8f8',
      200: '#e5e5e4',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262627',
      900: '#171717',
    },
    stone: {
      50: '#fafaf9',
      100: '#f5f5f4',
      200: '#e7e5e4',
      300: '#d6d3d1',
      400: '#a8a29e',
      500: '#78716c',
      600: '#57534e',
      700: '#44403c',
      800: '#292524',
      900: '#1c1917',
    },
    red: {
      50: '#fef2f2',
      100: '#fee2e2',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
    },
    orange: {
      50: '#fff7ed',
      100: '#ffedd5',
      200: '#fed7aa',
      300: '#fdba74',
      400: '#fb923c',
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
      800: '#9a3412',
      900: '#7c2d12',
    },
    yellow: {
      50: '#fefce8',
      100: '#fef9c3',
      200: '#fef08a',
      300: '#fde047',
      400: '#facc15',
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
      800: '#854d0e',
      900: '#713f12',
    },
    lime: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
    },
    emerald: {
      50: '#ecfdf5',
      100: '#d1fae5',
      200: '#a7f3d0',
      300: '#6ee7b7',
      400: '#34d399',
      500: '#10b981',
      600: '#059669',
      700: '#047857',
      800: '#065f46',
      900: '#064e3b',
    },
    teal: {
      50: '#f0fdfa',
      100: '#ccfbf1',
      200: '#99f6e4',
      300: '#5eead4',
      400: '#2dd4bf',
      500: '#14b8a6',
      600: '#0d9488',
      700: '#0f766e',
      800: '#115e59',
      900: '#134e4a',
    },
    cyan: {
      50: '#ecfeff',
      100: '#cffafe',
      200: '#a5f3fc',
      300: '#67e8f9',
      400: '#22d3ee',
      500: '#06b6d4',
      600: '#0891b2',
      700: '#0e7490',
      800: '#155e75',
      900: '#164e63',
    },
    sky: {
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#bae6fd',
      300: '#7dd3fc',
      400: '#38bdf8',
      500: '#0ea5e9',
      600: '#0284c7',
      700: '#0369a1',
      800: '#075985',
      900: '#0c4a6e',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
    },
    indigo: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
    },
    violet: {
      50: '#f5f3ff',
      100: '#ede9fe',
      200: '#ddd6fe',
      300: '#c4b5fd',
      400: '#a78bfa',
      500: '#8b5cf6',
      600: '#7c3aed',
      700: '#6d28d9',
      800: '#5b21b6',
      900: '#4c1d95',
    },
    purple: {
      50: '#faf5ff',
      100: '#f3e8ff',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#c084fc',
      500: '#a855f7',
      600: '#9333ea',
      700: '#7e22ce',
      800: '#6b21a8',
      900: '#581c87',
    },
    fuchsia: {
      50: '#fdf4ff',
      100: '#fae8ff',
      200: '#f5d0fe',
      300: '#f0abfc',
      400: '#e879f9',
      500: '#d946ef',
      600: '#c026d3',
      700: '#a21caf',
      800: '#86198f',
      900: '#701a75',
    },
    pink: {
      50: '#fdf2f8',
      100: '#fce7f3',
      200: '#fbcfe8',
      300: '#f9a8d4',
      400: '#f472b6',
      500: '#ec4899',
      600: '#db2777',
      700: '#be185d',
      800: '#9d174d',
      900: '#831843',
    },
    gray: {
      50: '#fafafa',
      100: '#f2f2f2',
      200: '#edecec',
      300: '#e2e2e2',
      400: '#d4d4d4',
      500: '#b5b5b5',
      600: '#8f8f8f',
      700: '#4f4f4f',
      800: '#3b3b3b',
      900: '#262626',
    },
    amber: {
      // https://tailwind.simeongriggs.dev/amber/FFDA7C
      50: '#fffbf0',
      100: '#fff8e5',
      200: '#fff1cc',
      300: '#ffe9b3',
      400: '#ffe194',
      500: '#ffda7c', // primary
      600: '#ffc42e',
      700: '#e6a400',
      800: '#996e00',
      900: '#4d3700',
    },
    green: {
      // https://tailwind.simeongriggs.dev/green/58DD4D
      50: '#f3fdf2',
      100: '#e7fae5',
      200: '#cbf4c7',
      300: '#abeea5',
      400: '#83e67a',
      500: '#58dd4d', // success
      600: '#34c926',
      700: '#2eb422',
      800: '#27961d',
      900: '#1c6b14',
    },
    rose: {
      // https://tailwind.simeongriggs.dev/red/FE7375
      50: '#fff5f5',
      100: '#ffebeb',
      200: '#ffd6d7',
      300: '#ffbdbe',
      400: '#fe9fa0',
      500: '#fe7375', // secondary, danger
      600: '#fe5356',
      700: '#fd070b',
      800: '#d50105',
      900: '#9d0104',
    },
  };
  
  export const colors = {
    primary: colorPalette.amber,
    secondary: colorPalette.rose,
    success: colorPalette.green,
    danger: colorPalette.rose,
    dark: colorPalette.dark,
    red: colorPalette.red,
    gray: colorPalette.gray,
    neutral: colorPalette.neutral,
  };
  
  export const themeColorKeys = [
    'primary',
    'secondary',
    'success',
    'danger',
  ] as const;
  