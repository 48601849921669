import axios from 'axios';
import { createHttp } from 'effector-http-api';

const $instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "ngrok-skip-browser-warning": "69420"
  }
});

const http = createHttp($instance);

export { http };