import { attach, createEffect, createEvent, createStore, restore, sample } from "effector";
import { api, type Tags, type User } from "../../models/api";

const opened = createEvent();

const getUsersByTag = attach({
  effect: createEffect(api.user.getLeads),
});

const activeUsers = createStore<User[]>([]);

const tagSelected = createEvent<Tags>();

const activeTag = restore(tagSelected, null);

sample({
  clock: opened,
  fn: () => 'init' as Tags,
  target: tagSelected,
});

sample({
  clock: tagSelected,
  fn: (tag) => ({ tag }),
  target: getUsersByTag,
});

sample({
  clock: getUsersByTag.done,
  fn: ({ result }) => (result),
  target: activeUsers,
});

export const tagModel = {
  activeTag,
  tagSelected,
  activeUsers,
  opened,
};
