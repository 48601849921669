import React, { useState } from "react";
import { createView } from "../../models/view";
import { postPageModel } from "../../pages/post/post.model";
import { CommentActionsType } from "./types";
import { Image } from "@mantine/core";
import { ConfirmDeleteModal } from "./confirm-delete-modal";

const CommentActions = createView<CommentActionsType>()
  .units({
    editComment: postPageModel.editComment,
    deleteComment: postPageModel.deleteComment,
  })
  .map(
    ({
      setEditId,
      setReplyId,
      id,
      editComment,
      newText,
      text,
      inputRef,
      deleteComment,
    }) => {
      const [isConfirmDelete, setIsConfirmDelete] = useState(false);

      const onActionButtonClick = (action: Function) => {
        return () => {
          setEditId(null);
          setReplyId(null);
          action();
        };
      };

      const onApplyEditButtonClick = onActionButtonClick(() => {
        if (newText !== text) {
          editComment({ commId: id, text: newText });
        }
        setEditId(null);
      });
      const onEditButtonClick = onActionButtonClick(() => setEditId(id));
      const onDeleteButtonClick = onActionButtonClick(() =>
        deleteComment({ commId: id })
      );
      const onReplyButtonClick = onActionButtonClick(() => {
        setReplyId(id);
        inputRef.current?.scrollIntoView();
        inputRef.current?.focus();
      });

      return {
        onEditButtonClick,
        onApplyEditButtonClick,
        onDeleteButtonClick,
        onReplyButtonClick,
        isConfirmDelete,
        setIsConfirmDelete,
      };
    }
  )
  .view(
    ({
      editId,
      id,
      tgId,
      tg,
      onEditButtonClick,
      onApplyEditButtonClick,
      onDeleteButtonClick,
      onReplyButtonClick,
      isConfirmDelete,
      setIsConfirmDelete,
    }) => (
      <>
        <div className="actions">
          {tgId === tg.id ? (
            <>
              {editId === id ? (
                <button
                  className="actionButton"
                  onClick={onApplyEditButtonClick}
                >
                  <Image color="white" src={"icons/tick.svg"} />
                </button>
              ) : (
                <button className="actionButton" onClick={onEditButtonClick}>
                  <Image color="white" src={"icons/pencil.svg"} />
                </button>
              )}

              <button className="actionButton" onClick={() => setIsConfirmDelete(true)}>
                <Image color="white" src={"icons/trash.svg"} />
              </button>
            </>
          ) : null}
          <button className="actionButton" onClick={onReplyButtonClick}>
            <Image color="white" src={"icons/reply.svg"} />
          </button>
        </div>
        <ConfirmDeleteModal
          opened={isConfirmDelete}
          onClose={() => setIsConfirmDelete(false)}
          deleteComment={onDeleteButtonClick}
        />
      </>
    )
  );

export default CommentActions;
