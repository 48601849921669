import React from "react";
import "./PersonItem.css";
import { User } from "../../models/api";
import { Card, Group, Image, Text, Badge, Stack, Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";

const PersonItem = ({
  user,
  className,
  onAdd,
}: {
  user: User;
  className: string;
  onAdd: (user: User) => void;
}) => {
  const onAddHandler = () => {
    onAdd(user);
  };
  
  return (
    <Link
      to={`/user?id=${user?.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Card
        shadow="xl"
        padding="xl"
        radius="xl"
        variant="light"
        maw={600}
        m={10}
        className="person-card"
      >
        <Group align="flex-start">
          <Carousel
            slideSize="auto"
            slideGap="sm"
            align="start"
            slidesToScroll={1}
            m={10}
            withControls={false}
            classNames={{
              viewport: "flex overflow-x-auto",
            }}
            className="carousel"
          >
            {user?.picturesUrl?.map((el, index) => (
              <Image
                key={`image-${user.id}-${index}`}
                className="person-img"
                src={el}
                height={"100%"}
                width={"100%"}
                style={{objectFit: "contain"}}
                alt={user.name}
              />
            ))}
          </Carousel>
          <Stack style={{ flex: 1 }}>
            <Stack gap={0}>
              <Text className="username-text">{user?.name}</Text>
              <Flex gap={"1vw"}>
                <Text className="subs-text">@{user.nick}</Text>
              </Flex>
              <Flex gap={"1vw"}>
                <span>Followers:</span>
                <Text className="subs-text">{user?.subscribers?.length}</Text>
              </Flex>
            </Stack>
          </Stack>
          <Group>
            {user?.tags?.map((el) => (
              <Badge color="cyan">{el}</Badge>
            ))}
          </Group>
        </Group>
        <Text size="sm" style={{ marginTop: 10 }}>
          {user?.description}
        </Text>
      </Card>
    </Link>
  );
};

export default PersonItem;
