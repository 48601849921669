import { Modal, Text, Button, Box, Title, Group } from "@mantine/core";
import { createView } from "../../../models/view";
import { eventModalModel } from "./event-modal.model";
import { useEffect } from "react";
import { Leader } from "../../../models/api";
import "../calendar.css";
import { MyEvent } from "../calendar.types";
import { useTelegram } from "../../../hooks/useTelegram";

interface EventModal {
  event: MyEvent;
  leader: Leader;
  opened: boolean;
  close: (reload?: boolean) => void;
}

export const parseTgIdAfterAtSign = (input: string): number | null => {
  const match = input.match(/@(\d+)/);
  return match ? Number(match[1]) : null;
};

const EventModal = createView<EventModal>()
  .displayName("EventModal")
  .units({
    eventSelected: eventModalModel.eventSelected,
    loading: eventModalModel.loading,
    result: eventModalModel.result,
    eventUnSelected: eventModalModel.eventUnSelected,
    loadingUnbooking: eventModalModel.loadingUnbooking,
    resultCansel: eventModalModel.resultCansel,
  })
  .effect(({ result, resultCansel, close }) => {
    useEffect(() => {
      if (result.success) {
        setTimeout(() => close(true), 1000);
      }
      if (resultCansel.success) {
        setTimeout(() => close(true), 1000);
      }
    }, [result, resultCansel]);
  })
  .map(({ event }) => {
    const { user } = useTelegram();
    let startDate = event.start;
    let endDate = event.end;

    let startYear = startDate.getFullYear();
    let startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
    let startDay = String(startDate.getDate()).padStart(2, "0");
    let startHours = String(startDate.getHours()).padStart(2, "0");
    let startMinutes = String(startDate.getMinutes()).padStart(2, "0");

    let formattedDate = `${startYear}-${startMonth}-${startDay} ${startHours}:${startMinutes}`;

    let endHours = String(endDate.getHours()).padStart(2, "0");
    let endMinutes = String(endDate.getMinutes()).padStart(2, "0");

    let isUserBooked = parseTgIdAfterAtSign(event.title) === user.id;

    return {
      formattedDate,
      endHours,
      endMinutes,
      startHours,
      startMinutes,
      isUserBooked,
    };
  })
  .memo()
  .view(
    ({
      opened,
      event,
      close,
      eventSelected,
      leader,
      formattedDate,
      loading,
      endHours,
      endMinutes,
      startHours,
      startMinutes,
      isUserBooked,
      eventUnSelected,
      loadingUnbooking,
    }) => {
      return (
        <Modal
          opened={opened}
          onClose={close}
          size="lg"
          title={
            <div>
              <h1>
                Событие {event.title} @{leader.nick}
              </h1>
              <h3>{`${startHours}:${startMinutes} - ${endHours}:${endMinutes}`}</h3>
            </div>
          }
          className="eventModal"
          styles={{
            content: {
              backgroundColor: "#212121",
              color: "white",
            },
            header: {
              backgroundColor: "#212121",
            },
          }}
        >
          <div>
            <h4>Описание:</h4>
            <Text>{event.description}</Text>
          </div>
          {event.title?.includes("#cost") ? (
            <Button
              radius={10}
              style={{ backgroundColor: "#212121", marginTop: "1rem" }}
              onClick={() => {
                eventSelected({
                  eventId: event.uid,
                  leadTgId: leader.telegramId,
                  start: formattedDate,
                });
              }}
              loading={loading}
            >
              Забронировать
            </Button>
          ) : null}
          {isUserBooked ? (
            <Button
              radius={10}
              style={{ backgroundColor: "#212121", marginTop: "1rem" }}
              onClick={() => {
                eventUnSelected({
                  eventId: event.uid,
                  leadTgId: leader.telegramId,
                  start: formattedDate,
                });
              }}
              loading={loadingUnbooking}
            >
              Разбронировать
            </Button>
          ) : null}
        </Modal>
      );
    }
  );

export default EventModal;
