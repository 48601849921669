import { attach, createEffect, createEvent, createStore, restore, sample } from "effector";
import { Leader, api } from "../../models/api";
import { useTelegram } from "../../hooks/useTelegram";
import { CalendarComponent } from "../../components/Calendar/calendar.types";

const { user: tgUser } = useTelegram();
const setUserId = createEvent<string>('');
const setUser = createEvent<Leader | null>();
const toggleSubc = createEvent();
const toggleUnSubc = createEvent();
const toggleReload = createEvent<{timeEnd: string; timeStart: string}>();
const updateUserCalendar = createEvent<CalendarComponent[]>()

const userId = createStore<string>('')
  .on(setUserId, (_, id) => id);
  
const tgUserStore = createStore(tgUser);
const user = restore(setUser, null)
  .on(updateUserCalendar, (state, events) => {
    if (state && events) {
      return { ...state, calendar: events };
    }
    return state;
  });

const getUser = attach({
  effect: createEffect(api.user.getLead),
});
const getLeadEvents = attach({
  effect: createEffect(api.user.getLeadEvents),
});
const checkSubscribe = attach({
  effect: createEffect(api.user.checkSubscribe),
});
const subscribe = attach({
  effect: createEffect(api.user.subscribe),
});
const unsubscribe = attach({
  effect: createEffect(api.user.unsubscribe),
});

// const getCalendar = attach({
//   effect: createEffect(async (user: Leader | null) => {
//     const response = await axios.get('http://localhost:3001/calendar');
//     if (user && response) {
//       user.calendar = response.data;
//     }
//     return user;
//   }),
// });

const isSubscriber = createStore<boolean>(false).on(checkSubscribe.done, (_, { result }) => result.isSubscribed);
const getUserPending = getUser.pending;
const getLeadEventsPending = getLeadEvents.pending;

sample({
  clock: userId,
  fn: (id) => ({ id }),
  target: getUser,
});
sample({
  clock: toggleReload,
  source: userId,
  fn: (id, times) => ({ id, ...times }),
  target: getLeadEvents,
});

sample({
  clock: getLeadEvents.done,
  fn: ({ result }) => result,
  target: updateUserCalendar,
});

sample({
  clock: getUser.done,
  fn: ({ result }) => (result && result.lead ? result.lead : null),
  target: setUser,
});

// sample({
//   clock: getCalendar.done,
//   fn: ({ result }) => (result ? result : null),
//   target: setUser,
// });

sample({
  clock: userId,
  source: tgUserStore,
  fn: (tgUserStore, id) => ({ leadId: id, userId: tgUserStore?.id ?? 0 }),
  target: checkSubscribe,
});

sample({
  clock: toggleSubc,
  source: { tgUserStore: tgUserStore, userId: userId },
  fn: ({ tgUserStore, userId }) => ({ leadId: userId, userId: tgUserStore?.id ?? 0 }),
  target: subscribe,
});

sample({
  clock: toggleUnSubc,
  source: { tgUserStore: tgUserStore, userId: userId },
  fn: ({ tgUserStore, userId }) => ({ leadId: userId, userId: tgUserStore?.id ?? 0 }),
  target: unsubscribe,
});

sample({
  clock: unsubscribe.done,
  source: { tgUserStore: tgUserStore, userId: userId },
  fn: ({ tgUserStore, userId }) => ({ leadId: userId, userId: tgUserStore?.id ?? 0 }),
  target: checkSubscribe,
});

sample({
  clock: subscribe.done,
  source: { tgUserStore: tgUserStore, userId: userId },
  fn: ({ tgUserStore, userId }) => ({ leadId: userId, userId: tgUserStore?.id ?? 0 }),
  target: checkSubscribe,
});
export const userPageModel = {
  setUserId,
  userId,
  getUser,
  user,
  isSubscriber,
  toggleSubc,
  toggleUnSubc,
  toggleReload,
  getUserPending,
  tgUserStore,
  getLeadEventsPending,
}