import { Link, useLocation } from "react-router-dom";
import { Comment } from "../../models/api";
import { Center, Loader, Stack } from "@mantine/core";
import { createView } from "../../models/view";
import { postPageModel } from "./post.model";
import { useEffect } from "react";
import "./post.css";
import CommentsSection from "../../components/Comments/comments-section";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PostPage = createView<object>()
  .displayName("PostPage")
  .units({
    setPostId: postPageModel.setPostId,
    post: postPageModel.post,
    comments: postPageModel.comments,
  })
  .map(({ setPostId }) => {
    const query = useQuery();
    const id = query.get("id");

    useEffect(() => {
      if (id) {
        setPostId(id);
      }
    }, [id, setPostId]);

    return {};
  })
  .memo()
  .view(({ post, comments }) => {
    if (!post) {
      return (
        <Center style={{ height: "100vh" }}>
          <Loader size="xl" />
        </Center>
      );
    }
    if (!comments) {
      return (
        <Center style={{ height: "100vh" }}>
          <Loader size="xl" />
        </Center>
      );
    }

    return (
      <Stack style={{height: "100%"}}>
        <CommentsSection commentsData={comments} />
      </Stack>
    );
  }).Memo;

export default PostPage;
